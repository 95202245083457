"use client";

import {
  ArrowRightIcon,
  CircleCheckBig,
  CircleCheckBigIcon,
  Minus,
  Plus,
} from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { useAppSelector } from "@/hooks/RTKTypedHooks";
import { useRouter } from "next/navigation";
import { buyBasicPlan } from "@/actions/plans/buy-basic-plan";
import { User } from "@/store/appState";
import { useState } from "react";

interface PriceItemProps {
  price: string;
  yearly_price?: string;
  reduction?: string;
  description: string;
  main?: boolean;
  title: string;
  features: string[];
  available: boolean;
}

const items: PriceItemProps[] = [
  {
    available: true,
    title: "Free",
    price: "0€",
    description:
      "Idéal pour tester rapidement son niveau et les fonctionnalités du site.",
    features: [
      "1 test express (3 questions) / mois",
      "5 min de conversation avec l'IA (sans vocals)",
    ],
  },
  {
    available: true,
    title: "Basic",
    price: "25€",
    yearly_price: "240€",
    reduction: "10%",
    description:
      "Formule principale pour profiter pleinement de toutes nos fonctionnalités.",
    main: true,
    features: [
      "Test express (3 questions) / mois",
      "Test complet (10 ou 20 questions)",
      "+ de 10 heures de conversation avec l'IA",
      "Accés à vos statistiques de progression",
      "Possibilité d'envoyer des vocaux et d'en recevoir",
      "Synthèse des axes d'amélioration",
    ],
  },
  {
    available: false,
    title: "Pro",
    price: "30€",
    description:
      "Offre personnalisée pour chaque équipe. Prix dégressif pour les équipes de plus de 5 personnes.",
    features: [
      "Test express (3 questions) / mois",
      "Test complet (10 ou 20 questions)",
      "10 heures de conversation avec l'IA",
      "Accés à vos statistiques de progression",
      "Possibilité d'envoyer des vocaux et d'en recevoir",
      "Synthèse des axes d'amélioration",
      "Création de comptes pour votre équipe",
      "Suivi de l'évolution de votre équipe",
    ],
  },
];

const PriceItem = ({
  item,
  loginOrBuy,
  user,
  billFrequency,
  setBillFrequency,
}: {
  item: PriceItemProps;
  loginOrBuy: () => Promise<void>;
  user: User;
  billFrequency: boolean;
  setBillFrequency: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className={`${item.main && "mt-10"} w-11/12 lg:mt-0 lg:w-3/12`}>
      <div
        className={` rounded-t-md  p-2 text-center font-bold text-black ${
          !item.main
            ? "bg-inherit text-transparent"
            : "bg-[linear-gradient(to_right,#F87BFF,#FB92CF,#FFDD9B,#C2F0B1,#2FD8FE)]"
        }`}
      >
        Recommandé
      </div>

      <Card
        className={`${
          item.main ? "rounded-t-none" : ""
        } border-blue-400/50 bg-slate-900 dark:bg-slate-50`}
      >
        <CardHeader className='text-center'>
          <h2 className='bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-5xl font-bold text-transparent'>
            {item.title}
          </h2>
        </CardHeader>
        <CardContent>
          <p className='mb-10 mt-5 text-center text-4xl font-extrabold text-white dark:text-black'>
            {item.title === "Pro" && (
              <span className='text-base'>À partir de </span>
            )}
            {billFrequency && item.title === "Basic"
              ? item.yearly_price
              : item.price}
            <span className='text-base'>
              {" "}
              / {billFrequency && item.title === "Basic" ? "an" : "mois"}
            </span>
            {item.title === "Basic" && (
              <span
                className={`block text-xs transition-opacity duration-500 ease-in-out ${
                  billFrequency ? "opacity-100" : "h-0 opacity-0"
                }`}
              >
                (-{item.reduction} de réduction)
              </span>
            )}
          </p>

          {item.title === "Free" && (
            <Link passHref href={user ? "/store" : "/auth/register"}>
              <p className='mb-10 flex cursor-pointer items-center justify-center text-center text-xl font-extrabold text-white dark:text-black'>
                Essais gratuit <ArrowRightIcon className='inline-block' />
              </p>
            </Link>
          )}
          {item.title !== "Free" && (
            <div className='relative mb-10'>
              {/* {item.title === "Basic" && (
                <div className='flex justify-center items-center text-white gap-2 w-6/12 mx-auto mb-3  rounded-md py-2 dark:bg-blue-900'>
                  <Switch
                    className='data-[state=checked]:bg-blue-700 data-[state=unchecked]:bg-blue-300'
                    checked={billFrequency}
                    onCheckedChange={() => setBillFrequency(!billFrequency)}
                    id='user-role'
                  />
                  <Label>{billFrequency ? "Annuel" : "Mensuel"}</Label>
                </div>
              )} */}

              <Button
                disabled={!item.available}
                className=' mx-auto flex items-center justify-center text-center text-3xl font-extrabold text-white dark:text-black'
                onClick={loginOrBuy}
              >
                Commencer{" "}
                <ArrowRightIcon className='ml-2 inline-block' size={30} />
              </Button>
              {!item.available && (
                <p className='absolute -bottom-8 left-1/4 mx-auto mt-1 text-center text-base font-light text-yellow-300'>
                  Bientôt disponible
                </p>
              )}
            </div>
          )}

          <div className='text-white dark:text-black'>
            <h3 className=' text-xl font-bold'>Services</h3>
            <ul>
              {item.features.map((feature, index) => (
                <li key={index} className='my-2'>
                  <div className='flex gap-x-3'>
                    <CircleCheckBig className='size-5 text-blue-500' />
                    <p className='w-full text-sm'>{feature}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export const Pricing = () => {
  const user = useAppSelector((state) => state.user);
  const router = useRouter();
  const [billFrequency, setBillFrequency] = useState(false);
  const loginOrBuy = async () => {
    if (user.id) {
      await buyBasicPlan({ userId: user?.id!, billFrequency });
    } else {
      router.push("/auth/login");
    }
  };

  return (
    <div
      id='pricing'
      className='bg-slate-50 bg-gradient-to-b from-[#5e8fdb] to-slate-50 py-[72px] dark:bg-black dark:to-black dark:text-white sm:py-24'
    >
      <div className='container'>
        <h2 className='text-center text-5xl font-bold tracking-tighter sm:text-6xl'>
          Tarifs
        </h2>
        <div className='mt-12 flex flex-col items-center justify-center align-bottom lg:flex-row lg:items-start'>
          {items.map((item, index) => (
            <PriceItem
              loginOrBuy={loginOrBuy}
              user={user}
              item={item}
              key={index}
              billFrequency={billFrequency}
              setBillFrequency={setBillFrequency}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
