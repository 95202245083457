"use client";
import { useState } from "react";
import clsx from "clsx";

import PlusIcon from "../assets/icons/plus.svg";
import MinusIcon from "../assets/icons/minus.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Minus, Plus } from "lucide-react";

const items = [
  {
    question: "Quelles méthodes de paiement acceptez-vous?",
    answer:
      "Nous acceptons toutes les cartes de crédits. Contactez nous pour plus d'informations sur les méthodes de paiement disponibles dans votre région.",
  },
  {
    question: "Comment le prix est adapté aux équipes?",
    answer:
      "Notre tarif est par utilisateur/mois. Cela signifie que vous payez pour chaque utilisateur de votre équipe. Des rabais sont disponnibles pour les équipes plus importantes et les abonnements annuels. Pour plus d'informations, veuillez nous contacter.",
  },
  {
    question: "Puis-je changer mon plan de paiement plus tard?",
    answer:
      " Oui, vous pouvez changer de plan de paiement à tout moment Ou bien même y mettre fin. Les modifications apportées au plan de paiement seront proratisées et reflétées dans votre prochain cycle de facturation. En revanche la résiliation de votre abonnement vous laisse la possibilité de vous servir de votre crédit restant jusqu'à la fin du mois.",
  },
  {
    question: "Puis-je choisir mon niveau d’anglais pour les conversations?",
    answer:
      "Pour les tests, le niveau des questions va de façile à difficile afin que le resultat soit plus équitable. Pour les conversations réelles, nous vous recommandons de choisir le niveau qui vous convient le mieux afin de progresser à votre rythme. Une fois que vous êtes à l'aise avec ce niveau vous pouvez passer au niveau supérieur.",
  },
  {
    question: "Comment puis-je suivre mes progrès?",
    answer:
      "Vous pouvez suivre votre progression en allant sur la page 'mes statistiques', choisissez le module de test que vous souhaitez suivre. Vous aurez alors accès à votre score et à vos résultats avec un graphique pour visualiser rapidement vos progrès.",
  },
  {
    question: "Comment puis-je améliorer ma prononciation avec l’IA?",
    answer:
      "Si l'IA vous comprends c'est que vous prononcez correctement. Vous pouvez vérifier si l'IA à bien compris votre phrase en affichant la transcription de votre vocal. Vous pouvez aussi demander à l'IA dans les modules de conversations de lire du texte pour que vous puissiez prendre exemple.",
  },
  {
    question: "Puis-je revoir mes anciens tests et conversations?",
    answer:
      "Pour les tests, vous n'aurez pas accès aux questions et réponses des anciens tests. Pour les conversations avec l'IA, vous pouvez toujours les revoir et même les réécouter.",
  },
  {
    question:
      "Y a-t-il des exercices spécifiques pour les entretiens d’embauche en anglais?",
    answer:
      "Oui, très prochainement nous allons sortir un module spécialement dédié aux entretiens d’embauche en anglais. Vous pourrez ainsi tester votre niveau d’anglais et améliorer votre prononciation dans un domaine bien spécifique.",
  },
];

const AccordionItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className='cursor-pointer border-b border-black/30 py-7 dark:border-white/30'
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className='flex items-center'>
        <span className='flex-1 select-none font-bold sm:text-xl'>
          {question}
        </span>
        {isOpen ? <Minus /> : <Plus />}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={clsx("mt-4", { hidden: !isOpen, "": isOpen })}
            initial={{ opacity: 0, height: 0, marginTop: 0 }}
            animate={{ opacity: 1, height: "auto", marginTop: 16 }}
            exit={{ opacity: 0, height: 0, marginTop: 0 }}
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const FAQs = () => {
  return (
    <div className='bg-slate-50  py-[72px] dark:bg-black dark:text-white sm:py-24'>
      <div className='container'>
        <h2 className='text-center text-5xl font-bold tracking-tighter sm:text-6xl'>
          F.A.Q
        </h2>
        <div className='mx-auto mt-12 max-w-5xl'>
          {items.map((item, index) => (
            <AccordionItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
