/* eslint-disable react/no-unescaped-entities */
"use client";
import Image from "next/image";
import { motion, useScroll, useTransform } from "framer-motion";
import fluentAiShowcase from "@/public/static/images/fluant.ai.showcase2.png";
import { useRef } from "react";
import { log } from "console";

export const ProductShowcase = () => {
  const appImage = useRef<HTMLImageElement>(null);

  const { scrollYProgress } = useScroll({
    target: appImage,
    offset: ["start end", "end end"],
  });

  const rotateX = useTransform(scrollYProgress, [0, 1], [15, 0]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0.5, 1]);

  return (
    <div className=' bg-slate-50 bg-gradient-to-b from-slate-50 to-[#5e8fdb] py-[72px] text-black dark:bg-black dark:from-black dark:text-white sm:py-24'>
      <div className='container'>
        <h2 className='text-center text-5xl font-bold tracking-tighter sm:text-6xl'>
          Interface intuitive
        </h2>
        <div className='mx-auto max-w-3xl'>
          <p className='mt-5 text-center dark:text-white/70'>
            Une interface intuitive pour un apprentissage fluide. Accédez
            facilement à toutes les fonctionnalités et concentrez-vous sur
            l'essentiel : progresser en anglais.
          </p>
        </div>
        <motion.div
          style={{
            opacity: opacity,
            rotateX: rotateX,
            transformPerspective: "800px",
          }}
        >
          <Image
            src={fluentAiShowcase}
            alt='App screen'
            className='mx-auto mt-14 rounded-md shadow-2xl'
            ref={appImage}
          />
        </motion.div>
      </div>
    </div>
  );
};
