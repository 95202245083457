/* eslint-disable react/no-unescaped-entities */
"use client";

import { Button } from "@/components/ui/button";
import { motion, useScroll, useTransform } from "framer-motion";
import Link from "next/link";
import { useRef } from "react";

export default function Hero() {
  const containerRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const translateY = useTransform(scrollYProgress, [0, 1], [-40, 40]);
  return (
    <>
      <div
        className=' light:text-black relative overflow-hidden text-clip bg-[linear-gradient(to_bottom,#fff,#cfd9df_34%,#a1bddb_65%,#82a6db_82%)] py-[72px]  dark:bg-[linear-gradient(to_bottom,#000,#0d1042_34%,#2133a1_65%,#5e8fdb_82%)] dark:text-white sm:py-24'
        ref={containerRef}
      >
        <div className='absolute left-1/2 top-[calc(100%-96px)] h-[500px] w-[850px] -translate-x-1/2 rounded-[100%] border border-[#a0c4ff]/50 bg-slate-50  bg-[radial-gradient(closest-side,#f8fafc_90%,#a0c4ff)]  dark:border-[#1a2cb0]/50 dark:bg-[radial-gradient(closest-side,#000_90%,#1a2cb0)] sm:top-[calc(100%-120px)] sm:h-[768px] sm:w-[1536px] lg:h-[800px] lg:w-[2600px]' />
        <div className='container relative'>
          <div className='flex items-center justify-center'>
            <Link
              href={"/versions"}
              className='inline-flex gap-2 rounded-xl border border-white/30 bg-secondary px-3 py-2'
            >
              <span className='bg-[linear-gradient(to_right,#F87AFF,#FB93D0,#FFDD99,#C3F0B2)] bg-clip-text text-transparent [-webkit-background-clip:text]'>
                Version 1.0
              </span>
              <span className=' inline-flex items-center gap-1 dark:text-white'>
                <span>Découvrir les nouveautés</span>
                {/* <ArrowIcon /> */}
              </span>
            </Link>
          </div>
          <div className='mt-8 flex justify-center'>
            <div className='relative inline-flex'>
              <h1 className='text-center text-7xl font-bold tracking-tighter sm:text-9xl '>
                Speak English, <br /> Live Your Ambitions
              </h1>
              <motion.div
                className='absolute right-[576px] top-[120px]'
                style={{ translateY }}
              >
                {/* <Image
                  src={cursorImage}
                  alt='Cursor'
                  height={200}
                  width={200}
                  className='max-w-none'
                  draggable='false'
                /> */}
              </motion.div>
              <motion.div
                className='absolute left-[600px] top-[62px]'
                style={{ translateY }}
              >
                {/* <Image
                  src={messageImage}
                  alt='Message'
                  height={200}
                  width={200}
                  className='max-w-none'
                  draggable='false'
                /> */}
              </motion.div>
            </div>
          </div>
          <div className='flex justify-center'>
            <p className='mt-8 max-w-md text-center text-xl'>
              Apprenez l'anglais à votre rythme avec notre IA. Pratiquez à
              l'oral ou à l'écrit, testez vos compétences, et progressez
              rapidement
            </p>
          </div>
          <div className='mt-8 flex justify-center '>
            <Link href='/store' passHref>
              <Button className='p-6 text-3xl font-medium shadow-xl hover:bg-white/90 dark:bg-white dark:text-slate-950'>
                Essai gratuit
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
