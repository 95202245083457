"use server";
import prisma from "@/lib/db";
import { EmailSchema } from "@/schemas/newsLetter";

import { actionClient } from "@/lib/safe-action";

export const registerEmail = actionClient
  .schema(EmailSchema)
  .action(async ({ parsedInput: { email, antiSpam } }) => {
    
    if (antiSpam !== "") {
      return { failure: "Anti spam is not empty" };
    }
    const isEmailExisting = await prisma.newsLetterEmails.findUnique({
      where: {
        email,
      },
    });

    if (isEmailExisting) {
      return { failure: "Cet email est déjà utilisé" };
    }

    const res = await prisma.newsLetterEmails.create({
      data: {
        email,
      },
    });

    if (res) {
      return { success: "Email enregistré" };
    }
  });

/* export async function registerEmail(values: z.infer<typeof EmailSchema>) {
  const validatedFields = EmailSchema.safeParse(values);
  console.log(values)
  if (!validatedFields.success) throw new Error("Champs non valides");
  const { email, antiSpam } = validatedFields.data;
  if (antiSpam !== "") {
    return { error: "Anti spam is not empty" };
  }
  const isEmailExisting = await prisma.newsLetterEmails.findUnique({
    where: {
      email,
    },
  });

  if (isEmailExisting) {
    return { error: "Cet email est déjà utilisé" };
  }

  const res = await prisma.newsLetterEmails.create({
    data: {
      email,
    },
  });

  if (res) {
    return { success: "Email enregistré" };
  }
} */
